<template>
  <fieldset class="ui-radio-group">
    <span class="ui-radio-group__title">{{ title || $t('userGroup.show') + ':' }}</span>
    <label v-for="button in buttons" :key="button.label" class="ui-radio-button">
      {{ button.label }}
      <input
        v-model="getValue"
        type="radio"
        :value="button.value"
        :id="button.label"
        :name="buttons[0].label"
      >
      <span class="checkmark"></span>
    </label>
  </fieldset>
</template>

<script>
export default {
  name: 'UiRadioGroup',
  props: {
    buttons: {
      type: Array,
      default: () => [],
      required: true,
    },
    value: {
      type: [Object, Number, String],
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  watch: {
    value(newValue) {
      this.updateValue(newValue)
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)  // передаем значение наружу через v-model
    },
  },
}
</script>

<style lang="scss">
.ui-radio-group {
  display: flex;
  gap: 16px;
  border: none;
  align-items: center;
  &__title {
    color: var(--v-gray900-base);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }
  .ui-radio-button {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: var(--v-gray900-base);
    cursor: pointer;
    position: relative;
    padding-left: 24px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .checkmark {
        background-color: var(--v-blue600-base);
        border: none;

        &:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: -1px;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid var(--v-gray200-base);

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 5px;
        left: 5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
      }
    }
  }
}
</style>