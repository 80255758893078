<template>
  <form>
    <sticky-header class="d-flex align-center justify-space-between">
      <slot name="header" :on-submit="onSubmit">
        <v-row align="center">
          <v-col cols="8">
            <div class="black--text text--darken-6 text-h1 py-5">
              {{ title }}
            </div>
          </v-col>
          <v-col cols="4" align="right">
            <slot name="actions">
              <ui-button v-if="!hideBack" variant="white" w120 class="mx-3" @click="$router.push({ name: pathName })">
                {{ $t('base.back') }}
              </ui-button>
              <ui-button v-if="checkPermission" w120 :disabled="isLoading" @click="onSubmit">
                {{ $t('base.save') }}
              </ui-button>
            </slot>
          </v-col>
        </v-row>
      </slot>
    </sticky-header>
    <slot></slot>
  </form>
</template>

<script>
import StickyHeader from '@/components/ui/StickyHeader'
import { hasPermission } from '@/helpers/permission'

export default {
  name: 'ui-form-entity',
  components: { StickyHeader },
  props: {
    title: {
      type: String,
      default: '',
    },
    pathName: {
      type: String,
      default: '',
    },
    permission: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hideBack: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkPermission() {
      return this.permission.length ? this.hasPermission(this.permission) : true
    },
  },
  methods: {
    hasPermission,
    onSubmit() {
      this.$emit('onSubmit')
    },
  },
}
</script>

<style lang="scss"></style>
