<template>
  <v-tabs
    class="ui-tabs"
    background-color="transparent"
    :grow="grow"
    v-bind="$attrs"
    :height="height"
    v-on="$listeners"
  >
    <v-tab
      v-for="(item, index) in tabs"
      :key="item.tab"
      :to="
        item.to || {
          name: $route.name,
          query: { ...$route.query, tab: item.tab },
          params: { ...$route.params },
        }
      "
      :disabled="!!item.disabled"
      exact
      exact-active-class="text--darken-6"
      class="black--text text--darken-1 px-6"
      :class="tabSmall && 'v-tab-small'"
    >
      {{ item.title }}
      <slot name="iconTitle" v-if="index === numTabForIcon" />
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'ui-tabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tabSmall: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: 40,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    numTabForIcon: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    const tabsWrapper = document.querySelector('.ui-tabs');
    tabsWrapper.addEventListener('wheel', this.handleScroll, {passive: false});
  },
  beforeDestroy() {
    const tabsWrapper = document.querySelector('.ui-tabs');
    if (tabsWrapper) tabsWrapper.removeEventListener('wheel', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      event.preventDefault();
      if (event.deltaX !== 0) {
        event.currentTarget.scrollLeft += event.deltaX;
      } else if (event.deltaY !== 0) {
        event.currentTarget.scrollLeft += event.deltaY;
      }
    },
  }
}
</script>

<style lang="scss">
.ui-tabs {
  .v-tab {
    background-color: var(--v-black-lighten6);
    border-radius: 6px 6px 0 0;
    margin-right: 8px;
    overflow: hidden;

    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: none;
  }

  .v-tab-small {
    /*min-width: 80px!important;*/
    color: var(--v-black-darken1);
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px;
    padding: 8px 12px !important;
    margin-right: 4px !important;
  }

  .v-tab--active {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    background-color: white;
    letter-spacing: inherit;
  }

  .v-tabs-slider-wrapper {
    display: none;
  }
}

.offer-tabs {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }

  .v-slide-group__wrapper {
    overflow: inherit;

    .v-tab {
      white-space: nowrap;
      margin-right: 5px;
    }

    .v-tab:last-child {
      margin-right: 0;
    }
  }
}
</style>
